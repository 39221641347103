






































import FileResource from '@/models/graphql/FileResource';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import { TranslateResult } from 'vue-i18n';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { Getter, namespace } from 'vuex-class';
import Community from '@/models/graphql/Community';
import { deepGet } from '@/utils/ObjectHelpers';
import EntityType from '@/utils/enums/EntityType';
import CommunityUserFollow from '@/models/graphql/CommunityUserFollow';
import CommunityUser from '@/models/graphql/CommunityUser';
import { CommunityUserFollowFilter } from '@/graphql/_Filters/CommunityUserFollowFilter';
import BaseCardWidget from '@/components/cards/BaseCardWidget.vue';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';

const communityUserFollowStore = namespace('CommunityUserFollowStore');

@Component({
  components: {
    ButtonIconComponent,
    FontAwesomeComponent,
  },
})
/* eslint-disable no-underscore-dangle */
export default class TopicCardWidget extends BaseCardWidget {
  @Prop({ default: null })
  readonly cardRoute!: string;

  @Getter
  community!: Community;

  protected isFollowing = false;

  @Prop({ required: true })
  private uid!: string;

  @Prop({ required: true })
  private name!: string;

  @Prop({ required: false })
  private imageFileResource!: FileResource;

  @Prop({ required: false })
  private _followerCount!: number;

  @Prop({ required: false, default: null })
  private _isFollowed!: string;

  @Getter
  private authUser!: CommunityUser;

  @communityUserFollowStore.Action
  private follow!: (payload: CommunityUserFollowFilter) => Promise<CommunityUserFollow | undefined>;

  @communityUserFollowStore.Action
  private unFollowEntity!: (payload: {
    userId: string;
    entityId: string;
  }) => Promise<CommunityUserFollow | undefined>;

  private EntityType = EntityType;

  private get imageSrc(): string {
    if (this.imageFileResource) {
      return FileResourceHelper.getFullPath(this.imageFileResource, 'w256');
    }
    if (this.community.defaultExhibitorBannerFileResource) {
      return FileResourceHelper.getFullPath(this.community.defaultExhibitorBannerFileResource, 'w256');
    }
    return '/img/banner/empty.svg';
  }

  private get followerSubtitle(): TranslateResult | string {
    if (this._followerCount === 1) {
      return `${this._followerCount} ${this.$t('app.feed-page.follower')}`;
    }
    return `${this._followerCount} ${this.$t('app.feed-page.followers')}`;
  }

  private get handleBaseUrl(): string | null {
    if (this.cardRoute) {
      let r = this.cardRoute;
      const matches = this.cardRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }

  @Watch('_isFollowed', { immediate: true })
  protected setIsFollowing(): void {
    this.isFollowing = !!this._isFollowed;
  }

  protected toggleFollow(entityUid: string, entityType: string): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.isFollowing) {
      this.isFollowing = false;
      if (entityUid) {
        this.unFollowEntity({
          userId: this.authUser.uid,
          entityId: entityUid,
        }).catch(() => {
          this.isFollowing = true;
        });
      }
    } else {
      this.isFollowing = true;
      this.follow({
        userId: this.authUser.uid,
        linkedUserId: entityUid,
        entityType,
      }).then(() => {
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code as string,
          entityType,
          StatLoggerActions.ADD,
          '',
          -1,
          entityUid,
          StatLoggerCategories.FOLLOW,
          this.$i18n.locale,
        );
      }).catch(() => {
        this.isFollowing = false;
      });
    }
  }
}
